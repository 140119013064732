<template>
  <form id="frm_action">
    <div class="p-formgrid">
        <div class="p-card-field">
            <label class="p-col-fixed txt-right">Quốc gia <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Quốc gia" class="input_width" @search="SearchCountry" v-on:input="change_country" id= "country" placeholder="Chọn" v-model="selectedCountry" :options="country" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_country">
          </div>
          </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Tỉnh/ Thành Phố <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Tỉnh/ Thành Phố" id= "state" @search="SearchState" v-on:input="change_state" class="input_width" :filterable="false" placeholder="Chọn" v-model="selectedState" :options="state" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_state">
          </div>

        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right">Quận/ Huyện <span style="color: red">*</span></label>
          <div class="p-col">
            <v-select :clearable="false" title="Quận/ Huyện" id= "district" @search="SearchDistrict" class="input_width" :filterable="false" placeholder="Chọn" v-model="selectedDistrict" :options="district" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Không có dữ liệu
              </span>
            </v-select>
            <input class="input_tmp_validator" id="vali_district">
          </div>


        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right"> Phân Loại <span style="color: red">*</span></label>
          <div class="p-col">
            <Dropdown id="type" title="Phân Loại" class="input_width border_color" v-model="selectedType" :options="type" optionLabel="name" optionValue="id" placeholder="Chọn" />
              <span slot="no-options" @click="$refs.select.open = false">
              </span>
            <input class="input_tmp_validator" id="vali_type">
          </div>
        </div>
      <div class="p-card-field distance-dif">
        <label class="p-col-fixed txt-right margin " id="label">Khoảng cách liên tỉnh tính từ kho</label>
        <div class="p-inputgroup input-dif">
              <InputNumber  maxlength="50" min="0" max="100000000000" v-model="area.distance" id="distance"  type="text" placeholder="Nhập"  class="p-col-width input_distance"/>
          <span class="p-inputgroup-addon" id="km">Km</span>
        </div>
      </div>
        <div class="p-card-field div_check">
            <div class="p-col">
              <Checkbox  v-model="area.checked" @click="active" :binary="true"/>
              <label class="p-col-fixed txt-right text">Hiển thị</label>
            </div>
          </div>
      <Toolbar class="fixed-bottom-toolbar div-button ">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-warning callback-btn"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import DataServices from '@/core/DataServices'
import dataServices from "@/core/DataServices";
// import gql from "graphql-tag";

export default {
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {

      area:{
        distance: null,
        checked: true,

      },
      selectedCountry: null,
      selectedState: null,
      selectedDistrict: null,
      selectedType: null,
      country: [],
      state: [],
      district: [],
      type: [],
      list_district_all: [],
      list_state_all: [],
      list_country_all: [],
      code: '',
      validationErrors: {},
      formMode: this.mode,
      images: [],
      imagesDeletes: [],
      maxFileSize: 1000000, // 1MB
      maxFiles: 10,
      account_exist: false,
      firebaseID: null,
      userID: null,
      list_group_role:[],
      saving:false,
      addressData:{"street_number":"251","route":"Quang Trung","administrative_area_level_1":"Thành phố Hồ Chí Minh","country":"Việt Nam","latitude":10.8286588,"longitude":106.6732573},
      placeResultData:{"address_components":[{"long_name":"251","short_name":"251","types":["street_number"]},{"long_name":"Quang Trung","short_name":"Quang Trung","types":["route"]},{"long_name":"Gò Vấp","short_name":"Gò Vấp","types":["administrative_area_level_2","political"]},{"long_name":"Thành phố Hồ Chí Minh","short_name":"Thành phố Hồ Chí Minh","types":["administrative_area_level_1","political"]},{"long_name":"Việt Nam","short_name":"VN","types":["country","political"]}],"adr_address":"<span class=\"street-address\">251 Quang Trung</span>, <span class=\"extended-address\">Phường 10</span>, <span class=\"locality\">Gò Vấp</span>, <span class=\"region\">Thành phố Hồ Chí Minh</span>, <span class=\"country-name\">Việt Nam</span>","formatted_address":"251 Quang Trung, Phường 10, Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","geometry":{"location":{"lat":10.8286588,"lng":106.6732573},"viewport":{"south":10.8273459697085,"west":106.6719226697085,"north":10.8300439302915,"east":106.6746206302915}},"icon":"https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png","name":"251 Quang Trung","place_id":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","plus_code":{"compound_code":"RMHF+F8 Gò Vấp, Thành phố Hồ Chí Minh, Việt Nam","global_code":"7P28RMHF+F8"},"reference":"ChIJ8-A91QMpdTERWj0x_fnFL2Y","types":["street_address"],"url":"https://maps.google.com/?q=251+Quang+Trung,+Ph%C6%B0%E1%BB%9Dng+10,+G%C3%B2+V%E1%BA%A5p,+Th%C3%A0nh+ph%E1%BB%91+H%E1%BB%93+Ch%C3%AD+Minh,+Vi%E1%BB%87t+Nam&ftid=0x31752903d53de0f3:0x662fc5f9fd313d5a","utc_offset":420,"html_attributions":[],"utc_offset_minutes":420}
    }
  },
  async mounted() {
console.log("sâs111111111111")
    // đổ dữ liệu khi edit
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
// lấy data đổ vào input
      var list_area = this.$commonFuction.convertJsonObject(this.model);
      this.area.checked = this.model.active
      this.area.distance = this.model.distance
      console.log("group channel", list_area)
    }







    // đổ danh sach  data ra khi add hoac edit select data
    var list_country = DataServices.getList('country');
    var where = {deleted: {_eq: false}};
    var resdata_country = await this.$apollo.mutate({
      mutation: list_country,
      variables: {
        where_key:where,
        orderBy: {created_at: 'desc'}

      }
    });
    var country = resdata_country.data['country'];
    this.country = country;

    console.log("country",this.country)
    // if (this.mode == "add"){

      this.selectedCountry = this.country[0]
      console.log("nodauroi",this.selectedCountry)
    // }

    // đổ danh sach  data ra khi add hoac edit select data
    var country_id = this.selectedCountry.id ? this.selectedCountry.id:""
    var where_state = {country_id: {_eq:country_id},deleted: {_eq: false}};
    var list_state = DataServices.getList('state');

    var resdata_state = await this.$apollo.mutate({
      mutation: list_state,
      variables: {
        where_key:where_state,
        orderBy: {created_at: 'desc'}
      }
    });
    var state = resdata_state.data['state'];
    this.state = state;

    // đổ danh sach  data ra khi add hoac edit select data
    var list_type = DataServices.getList('area_type');


    console.log("list_type_data")
    var resdata_type = await this.$apollo.mutate({
      mutation: list_type,
      variables: {
        where_key:where,
        orderBy: {created_at: 'desc'}
      }
    });
    console.log("resss",resdata_type)
    var type = resdata_type.data['area_type'];
    this.type = type;
    console.log("STATttE",this.type)



    // đổ data đã chọn  ra khi edit
    console.log(this.mode,"modeeeeee")
    if (this.mode == 'edit') {
console.log("âswqwqwq")
      var list_area_edit = this.$commonFuction.convertJsonObject(this.model);
      var selected_country = (list_area_edit.country) ? list_area_edit.country:[];
      this.selectedCountry = selected_country;
      console.log("dauroitroi22",list_area_edit)

      var selected_state = (list_area_edit.state) ? list_area_edit.state:[];
      this.selectedState = selected_state;

      var selected_district = (list_area_edit.district) ? list_area_edit.district:[];
      this.selectedDistrict = selected_district;

      var selected_type = (list_area_edit.area_type) ? list_area_edit.area_type:[];
      console.log("type1111111",selected_type)
      this.selectedType = selected_type.id;
      console.log("type111111112222",this.selectedType)


      // đổ dữ liệu theo cái id của country
      console.log("wqwqsasasa",this.selectedCountry)
      var id_country  =  this.selectedCountry.id
      console.log("ryeyrere",id_country)
      let variables_add_edit_state = {
        'where_key': {
          'country_id': {'_eq': id_country}
        }
      }
      var list_state_add_edit = DataServices.getList('state');
      var resdata_state_add_edit = await this.$apollo.mutate({
        mutation: list_state_add_edit,
        variables: variables_add_edit_state
      });
      var state_add_edit = resdata_state_add_edit.data['state'];
      this.state = state_add_edit;


      // đổ dữ liệu theo cái id của state
      console.log("wqwqsasasa",this.selectedState)
      var id_state  =  this.selectedState.id
      console.log("ryeyrere",id_state)
      let variables_add_edit_district = {
        'where_key': {
          'state_id': {'_eq': id_state}
        }
      }
      var list_district_add_edit = DataServices.getList('district');
      var resdata_district_add_edit = await this.$apollo.mutate({
        mutation: list_district_add_edit,
        variables: variables_add_edit_district
      });
      var district_add_edit = resdata_district_add_edit.data['district'];
      this.district = district_add_edit;



    }

  },
  methods: {



    async SearchCountry(search, loading){

      var list_country =  dataServices.getList("country")
      var resdata_country = await this.$apollo.mutate({
        mutation: list_country,
      });
      this.list_country_all = resdata_country.data['country'];
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('country',{"fields" : `id name plain_name`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.country = list_unit;
      }else{
        // cosnole.log("dasdasd")
        this.country = this.list_country_all
      }
      return this.country;
    },


    async SearchState(search, loading){
      var country_id = this.selectedCountry.id

      console.log("country_id",country_id)

       var where = {
         "where_key" :{
           "country_id": {"_eq": country_id}
         }
       }
        var list_state =  dataServices.getList("state")
      var resdata_state = await this.$apollo.mutate({
        mutation: list_state,
        variables:where
      });
      this.list_state_all = resdata_state.data['state'];
      console.log("cdjsdjdsds111",this.list_state_all);
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('state',{"fields" : `id name plain_name fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.state = list_unit;
      }else{

        this.state = this.list_state_all
        console.log("dasdasd",this.state)
      }
      return this.state;
    },


    async SearchDistrict(search, loading){
      var state_id  =  this.selectedState.id
      console.log("state_id",state_id)
      var where = {
        "where_key":{
          "state_id":{"_eq":state_id}
        }
      }
      var list_district = DataServices.getList("district");
      var resdata_district = await this.$apollo.mutate({
        mutation: list_district,
        variables:where
      });
      this.list_district_all = resdata_district.data['district'];
      console.log("twettwe")
      console.log("cdjsdjdsds",loading);
      if(search !== '' && search !== null){
        var where_key = {
          // plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
          deleted : {_eq : false},
          plain_name:{_ilike: "%"+this.$commonFuction.SkipVN(search).trim()+"%"},
        };
        let {data} = await this.$apollo.query({
          query: DataServices.getList('district',{"fields" : `id name plain_name fullname`}),
          variables: {
            where_key: where_key,
            orderBy:[{created_at : 'desc'}]
          },
          fetchPolicy: "network-only"
        })
        var list = data[Object.keys(data)];
        var list_unit = [];
        // console.log("list===",list);
        for (let i = 0; i < list.length; i++) {
          const e = list[i];
          list_unit.push({id : e.id, name :e.name});
        }
        this.district = list_unit;
      }
      else{
        // cosnole.log("dasdasd")
        this.district = this.list_district_all
        console.log("quanhuyen",this.district)
      }
      return this.district;
    },

    async change_country() {

      this.selectedState = null
      var id  =  this.selectedCountry.id
      let variables = {
        'where_key': {
          'country_id': {'_eq': id}
        }
      }
      var list_state = DataServices.getList('state');
      var resdata_state = await this.$apollo.mutate({
        mutation: list_state,
        variables: variables
      });
      var state = resdata_state.data['state'];
      this.state = state;

    },
    async change_state() {

      this.selectedDistrict = null
      var state_id  =  this.selectedState.id

      var list_district = DataServices.getList('area');
      var resdata_district = await this.$apollo.mutate({
        mutation: list_district,
        variables: {
          orderBy: {created_at: 'desc'}
        }
      });
      var district = resdata_district.data['area'];
      var list_district_id = []
      for (var i = 0 ; i < district.length ; i++)
      {
        var check_id = district[i].district ? district[i].district.id:0
          list_district_id.push(check_id)


        console.log("disasasa",district)

      }

      console.log("listdistrict",list_district_id)

      var where = {
        'where_key': {
          'id': {'_nin': list_district_id},
          'state_id': {'_eq': state_id}
        }
      }
      console.log("where",where)


      var list_district_selected = DataServices.getList('district');
      var resdata_district_selected = await this.$apollo.mutate({
        mutation: list_district_selected,
        variables: where
      });
      var district_not_select = resdata_district_selected.data['district'];
      console.log("sâsa",district_not_select)
      this.district =  district_not_select
    },
    async active() {
    },
    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      let state_add = document.getElementById("vali_state");
      if (this.$commonFuction.isEmptyObject(this.selectedState)){
        state_add.setCustomValidity("Tỉnh không được để trống!");
      }else {
        state_add.setCustomValidity("");
      }


      let district_add = document.getElementById("vali_district");
      if (this.$commonFuction.isEmptyObject(this.selectedDistrict)){
        district_add.setCustomValidity("Quận không được để trống!");
      }else {
        district_add.setCustomValidity("");
      }


      let type_add = document.getElementById("vali_type");
      if (this.$commonFuction.isEmptyObject(this.selectedType)){
        type_add.setCustomValidity("Loại không được để trống!");
      }else {
        type_add.setCustomValidity("");
      }




      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },

    async saveData() {


      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
       console.log("test4")
      console.log("checked",this.checked)

      var country_id ;
      var state_id ;
      var district_id ;
      var type_id ;


       console.log("selectedCountry",this.selectedCountry)
      country_id = (this.selectedCountry.id)
      state_id = (this.selectedState.id)
      district_id = (this.selectedDistrict.id)
      console.log("selectedDistrict",this.selectedDistrict.id)
      type_id = (this.selectedType)
      console.log("selectedType",this.selectedType)

      console.log("gjgjjjgf",country_id)
      // }

        var params = {
          distance  :this.area.distance,
          country_id:country_id,
          state_id:state_id,
          district_id:district_id,
          type_id:type_id,
          active:this.area.checked
        }
      console.log('saveData', params)
        // console.log("params:",params);
        // console.log("do dai ",this.selectedCompany.length);
        // console.log("selected company",this.selectedCompany[0].id)
        // console.log("company id",params.company_id);
        // console.log(params);
      // var name = (params.name.length) ? params.name.length:0
      // var code = (params.code.length) ? params.code.length:0
      console.log("nasnasasas",name)
console.log("sâsaaa");
        console.log('saveData', params)
        this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.p-button-warning{
  border: 1px solid #C40380 !important;

}
#back{
  color: #C40380 !important;
}
.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}

</style>
